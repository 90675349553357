import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Card, CardContent, CardHeader} from "@material-ui/core";
import SLOProtocol from "./SLOProtocol";
import Button from "@material-ui/core/Button";
import {WamlContext} from "../WAMLContent";
import {SLOPageTitle} from "./SLOPage";

const useStyles = makeStyles({
    card: {
      border: "0",
      borderRadius: "6px",
      color: "#000",
      background: "#fff",
      width: "100%",
      boxShadow: "2px 2px 10px 5px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      minWidth: "0",
      wordWrap: "break-word",
      fontSize: ".875rem"
    },
    cardHeaderDefault: {
      backgroundColor: "#fc9b42",
      color: "#FFF",
      paddingBottom: "10px"
    },
    cardHeaderDeploy: {
      backgroundColor: "#70aed4",
      color: "#FFF",
      paddingBottom: "10px"
    },
    headerButton: {
      color: "#FFF",
      borderColor: "#FFF",
      fontWeight: "bold",
    },
    cardPlain: {
      background: "transparent",
      boxShadow: "none"
    },
    cardProfile: {
      marginTop: "30px",
      textAlign: "center"
    },
    cardChart: {
      "& p": {
        marginTop: "0px",
        paddingTop: "0px"
      }
    },
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "#000",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#000",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "bold",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    },
    buttonText: {
      float: "left",
      marginTop: "10px",
    },
    buttonGroup: {
      float: "right",
      marginRight: "5px",
      marginLeft: "3px",
    }
  }
);

export interface SLOCardProps {
  title: string;
  // @ts-ignore
  wapp: any; // parsed WAML coming from SLOPage
  // @ts-ignore
  sloSpec?: any;
  deployID?: string;
  defaultsIndex?: number;
  deleteDefault?: (index: number) => void;
  deleteDeploy?: (deployID: string) => void;
}

const SLOCard = (props: SLOCardProps) => {
  const context = useContext(WamlContext);

  const classes = useStyles();

  const [wapp, setWapp] = React.useState<any>(props.wapp);
  const [requestSpec, setRequestSpec] = React.useState(props.sloSpec?.requestSpec || {})

  React.useEffect(() => {
    if (!props.wapp) {
      return;
    }

    setWapp(props.wapp);
  }, [props.wapp]);

  React.useEffect(() => {
    setRequestSpec(props.sloSpec?.requestSpec || {})
  }, [props.sloSpec])

  const onProtocolChange = React.useCallback((e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    const proto = name.split(".")[0]
    const id = name.replace(`${proto}.`, "")

    let newRequestSpec = {...requestSpec}

    if (!newRequestSpec[proto]) {
      newRequestSpec[proto] = {}
    }

    switch (id) {
      case "availability.percent":
        if (!newRequestSpec[proto].availability) {
          newRequestSpec[proto].availability = {}
        }
        newRequestSpec[proto].availability.percent = value
        break;
      case "latency.tier2.percent":
        if (!newRequestSpec[proto].latency) {
          newRequestSpec[proto].latency = {}
        }
        if (!newRequestSpec[proto].latency.tier2) {
          newRequestSpec[proto].latency.tier2 = {}
        }
        newRequestSpec[proto].latency.tier2.percent = value
        break;
      case "latency.tier2.seconds":
        if (!newRequestSpec[proto].latency) {
          newRequestSpec[proto].latency = {}
        }
        if (!newRequestSpec[proto].latency.tier2) {
          newRequestSpec[proto].latency.tier2 = {}
        }
        newRequestSpec[proto].latency.tier2.seconds = value
        break;
      case "latency.tier1.percent":
        if (!newRequestSpec[proto].latency) {
          newRequestSpec[proto].latency = {}
        }
        if (!newRequestSpec[proto].latency.tier1) {
          newRequestSpec[proto].latency.tier1 = {}
        }
        newRequestSpec[proto].latency.tier1.percent = value
        break;
      case "latency.tier1.seconds":
        if (!newRequestSpec[proto].latency) {
          newRequestSpec[proto].latency = {}
        }
        if (!newRequestSpec[proto].latency.tier1) {
          newRequestSpec[proto].latency.tier1 = {}
        }
        newRequestSpec[proto].latency.tier1.seconds = value
        break;
      default:
        console.error("Unknown input", id)
    }

    setRequestSpec(newRequestSpec)
  }, [wapp, requestSpec, setRequestSpec]);

  const onSave = React.useCallback(() => {
    if (props.deployID) {
      context.modifyWaml({
        ...wapp,
        deploy: {
          ...wapp.deploy,
          [props.deployID]: {
            ...wapp.deploy[props.deployID],
            slo: {
              requestSpec: requestSpec
            }
          }
        }
      }, {tab: SLOPageTitle});
    } else if (props.defaultsIndex !== undefined) {
      let newWapp = {
        ...wapp,
        defaults: [...wapp.defaults]
      }
      newWapp.defaults[props.defaultsIndex].slo = {
        ...wapp.defaults[props.defaultsIndex].slo,
        requestSpec: requestSpec
      }

      context.modifyWaml(newWapp, {tab: SLOPageTitle});
    }
  }, [wapp, requestSpec, context.modifyWaml])

  return (
    <Card className={classes.card}>
      <CardHeader
        className={props.deployID ? classes.cardHeaderDeploy : classes.cardHeaderDefault}
        title={props.title}
        action={
          <>
            <Button
              color={"secondary"}
              className={classes.headerButton}
              variant={"outlined"}
              onClick={() => {
                if (props.defaultsIndex !== undefined && props.deleteDefault) {
                  props.deleteDefault(props.defaultsIndex);
                } else if (props.deployID && props.deleteDeploy) {
                  props.deleteDeploy(props.deployID);
                }
              }}>Delete</Button>
            &nbsp;&nbsp;
            <Button
              color={"primary"}
              variant={"outlined"}
              className={classes.headerButton}
              onClick={onSave}
            >Stage Changes</Button>
          </>
        }
      />
      <CardContent>
        <SLOProtocol
          protocol="http"
          protocolSpec={requestSpec?.http}
          onChange={onProtocolChange}
        />
        <hr/>
        <SLOProtocol
          protocol="grpc"
          protocolSpec={requestSpec?.grpc}
          onChange={onProtocolChange}
        />
      </CardContent>
    </Card>
  )
}

export default SLOCard;
