import {makeStyles} from "@material-ui/core/styles";
import React, {useContext} from "react";
import {useApi} from "@backstage/core-plugin-api";
import {BartApiError, bartApiRef} from "../../../../../../api/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link, WarningPanel} from "@backstage/core-components";
import DiffViewer from "react-diff-viewer";
import Button from "@material-ui/core/Button";
import InlineCode from "../../../../../core/InlineCode/InlineCode";
import Modal from "../../../../../core/Modal/Modal";
import {encode} from "../../../../../../utils/encoding";
import {WamlContext} from "./WAMLContent";

export type DiffModalProps = {
  open: boolean;
  hidden?: boolean;
  toggleModal: () => void;
}

const useDiffModalStyles = makeStyles(_theme => ({
  fmtDiffWarning: {
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: "50%",
    marginTop: "1%"
  }
}));

const WAMLDiffModal = (props: DiffModalProps) => {
  const apiClient = useApi(bartApiRef);
  const context = useContext(WamlContext);

  const classes = useDiffModalStyles();
  const {open = false, toggleModal} = props;

  // this has to be a string to retain the formatting that is returned from bart-api
  const [localWaml, setLocalWaml] = React.useState<string>("")
  const [localWamlFormatLoading, setLocalWamlFormatLoading] = React.useState<boolean>(false);
  const [localWamlFormatError, setLocalWamlFormatError] = React.useState<string>("");

  const [saveChangesLocallyLoading, setSaveChangesLocallyLoading] = React.useState<boolean>(false);
  const [saveChangesLocallyError, setSaveChangesLocallyError] = React.useState<string>("");

  const [pullRequestModalOpen, setPullRequestModalOpen] = React.useState(false);

  const togglePullRequestModal = () => {
    setPullRequestModalOpen(!pullRequestModalOpen);
  }

  React.useEffect(() => {
    setLocalWamlFormatError("");

    setLocalWamlFormatLoading(true);
    apiClient.FormatWaml(context.localWaml)
      .then(r => {
        if (!r.waml) {
          return;
        }
        setLocalWaml(r.waml);
      })
      .catch((e: BartApiError) => {
        setLocalWamlFormatError(e.cause);
      })
      .finally(() => {
        setLocalWamlFormatLoading(false);
      });

  }, [context.originalWaml, context.localWaml]);

  React.useEffect(() => {
    if (open) {
      setLocalWamlFormatError("");
    }
  }, [open])

  const saveChangesLocally = () => {
    if (!context.localSessionID) {
      return;
    }

    setSaveChangesLocallyLoading(true);
    apiClient.UpdateWamlSyncDoc(context.localSessionID, encode(localWaml))
      .catch((e: BartApiError) => {
        setSaveChangesLocallyError(e.cause);
      })
      .finally(() => {
        setSaveChangesLocallyLoading(false);
      })
  }

  return (
    <Modal
      open={open}
      toggleModal={toggleModal}
      fullWidth
      title={<>
        WAML Diff

        <div className={classes.fmtDiffWarning}>Note: this might show
          more changes that are a result of running <InlineCode>bart fmt</InlineCode></div>
      </>}
      actions={
        <>
          <Button
            onClick={saveChangesLocally}
            color={"primary"}
            disabled={!context.localSessionID}
          >Save Changes Locally</Button>
          <Button
            onClick={togglePullRequestModal}
            color={"primary"}
            disabled={!context.hasStagedChanges}
          >Create Pull Request</Button>
        </>
      }
    >
      {(localWamlFormatLoading || saveChangesLocallyLoading) ? (
        <CircularProgress/>
      ) : (
        <>
          {localWamlFormatError && (
            <WarningPanel
              severity="error"
              title={"WAML Formatting Error"}
              defaultExpanded={true}
            >
              {localWamlFormatError}
            </WarningPanel>
          )}

          {saveChangesLocallyError && (
            <WarningPanel
              severity="error"
              title={"WAML Save Error"}
              defaultExpanded={true}
            >
              {saveChangesLocallyError}
            </WarningPanel>
          )}

          <DiffViewer
            oldValue={context.originalWaml}
            newValue={localWaml}
            splitView={true}
          />
          <WAMLDiffPullRequestModal
            open={pullRequestModalOpen}
            toggleModal={togglePullRequestModal}
            hidden={!pullRequestModalOpen}
          />
        </>
      )}
    </Modal>
  )
};

type WAMLDiffPullRequestModalProps = {
  open: boolean;
  hidden?: boolean;
  toggleModal: () => void;
}

const WAMLDiffPullRequestModal = (props: WAMLDiffPullRequestModalProps) => {
  const apiClient = useApi(bartApiRef);
  const context = useContext(WamlContext);

  const {open = false, toggleModal} = props;

  const [pullRequestURL, setPullRequestURL] = React.useState<string>("");
  const [createPullRequestError, setCreatePullRequestError] = React.useState<string>("")
  const [createPullRequestLoading, setCreatePullRequestLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!open || pullRequestURL) {
      return;
    }

    setCreatePullRequestLoading(true);
    apiClient.CreateWamlPR(context.repositoryEntity.metadata.name, context.localWaml, "feat(waml): updates from Switchboard", "Updates to the .weave.yaml file from the Switchboard").then(r => {
      setPullRequestURL(r.prUrl || "");
    }).catch((e: BartApiError) => {
      setCreatePullRequestError(e.cause);
    }).finally(() => {
      setCreatePullRequestLoading(false);
    })
  }, [open])

  return (
    <Modal
      open={open}
      toggleModal={toggleModal}
      title={createPullRequestLoading ? "Creating Pull Request" : "Pull Request Created!"}
    >
      {createPullRequestLoading && (
        <CircularProgress/>
      )}

      {createPullRequestError && (
        <WarningPanel
          severity="error"
          title={"Pull Request Creation Error"}
          defaultExpanded={true}
        >
          {createPullRequestError}
        </WarningPanel>
      )}

      {pullRequestURL && (
        <><Link to={pullRequestURL}>{pullRequestURL}</Link></>
      )}
    </Modal>
  )
}

export default WAMLDiffModal;
