import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Grid } from '@material-ui/core';

import { RepositoryEntityV1alpha1 } from '../../../../../../../../backend/src/custom-processors/types';

import { RiskScore } from '../overview/RiskScore';

const RiskScoreContent = () => {
  const { entity } = useEntity<RepositoryEntityV1alpha1>();

  if (!entity?.spec?.qualityMetricsReport) {
    return null;
  }

  return (
    <Grid>
      <RiskScore entity={entity} />
    </Grid>
  );
};

export default RiskScoreContent;
