import React, {useContext} from "react";
import {RepositoryEntityV1alpha1} from "backend/src/custom-processors/types";
import SLOPage, {SLOPageTitle} from "./slos/SLOPage";
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import WAMLDiffModal from "./WAMLDiffModal";
import WAMLDefinition, {WAMLDefinitionPageTitle} from "./definition/WAMLDefinition";
import InlineCode from "../../../../../core/InlineCode/InlineCode";
import WAMLRoutes from "./WAMLRoutes";

export type WamlContextProps = {
  repositoryEntity: RepositoryEntityV1alpha1;

  originalWaml: string;
  localWaml: string;
  hasStagedChanges: boolean;
  tabsWithChanges?: string[];
  localSessionID?: string;

  modifyWaml: (newWaml: string, options?: ModifyWamlOptions) => void;
  resetWaml: () => void;
}

export const WamlContext = React.createContext<WamlContextProps>({} as WamlContextProps);

export type ModifyWamlOptions = {
  tab?: string;
  sessionID?: string;
}

const WamlContent = () => {
  const context = useContext(WamlContext);
  const [diffModalOpen, setDiffModalOpen] = React.useState(false);

  const toggleDiffModal = () => {
    setDiffModalOpen(!diffModalOpen);
  }

  if (!context.localWaml) {
    return <>No <InlineCode>.weave.yaml</InlineCode> found</>;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{textAlign: "right"}}>
          <Button
            onClick={context.resetWaml}
            disabled={!context.hasStagedChanges}
            color={"primary"}
            variant={"outlined"}
            size={"small"}
            style={{marginTop: "-10px", marginRight: "10px"}}
          >Reset</Button>
          <Button
            onClick={toggleDiffModal}
            disabled={!context.hasStagedChanges}
            variant={"outlined"}
            style={{marginTop: "-10px"}}
            color={"primary"}
            size={"small"}
          >{context.hasStagedChanges ? "View Diff" : "No Diff"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <WAMLRoutes
            routes={[
              {
                path: "/",
                title: WAMLDefinitionPageTitle,
                content: (
                  <WAMLDefinition/>
                )
              },
              {
                path: "/slo",
                title: SLOPageTitle,
                content: (
                  <SLOPage/>
                )
              }
            ]}
          />
        </Grid>
      </Grid>

      <WAMLDiffModal
        toggleModal={toggleDiffModal}
        open={diffModalOpen}
      />
    </>
  )
}

export default WamlContent;
