import {useApi} from "@backstage/core-plugin-api";
import {BartApiError, bartApiRef} from "../../../../../../../../api/api";
import React, {useContext} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {WarningPanel} from "@backstage/core-components";
import Modal from "../../../../../../../core/Modal/Modal";
import InlineCode from "../../../../../../../core/InlineCode/InlineCode";
import YAML from "yaml";
import {Accordion, AccordionSummary, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  getBackgroundColorForSeverity,
  getConclusionFromResults,
  parseValidationData,
  resultIsEmpty,
  resultsAreEmpty,
  ValidationData,
  ValidationResult
} from "./types";
import ValidationResultCard from "./ValidationResultCard";
import {WamlContext} from "../../WAMLContent";

const useStyles = makeStyles(_theme => ({
  accordion: {
    marginBottom: "1%"
  },
  validationResultAccordionSummary: {
    fontSize: "15px",
    fontWeight: "bold"
  }
}))

type Props = {
  open: boolean;
  hidden?: boolean;
  toggleModal: () => void;
}

const WAMLValidationModal = (props: Props) => {
  const classes = useStyles();
  const context = useContext(WamlContext);
  const apiClient = useApi(bartApiRef);
  const {open = false, toggleModal} = props;

  const [wapp, setWapp] = React.useState<any>("");

  const [validationResponseIsValid, setValidationResponseIsValid] = React.useState<boolean>();
  const [validationResponse, setValidationResponse] = React.useState<ValidationData>();
  const [validationLoading, setValidationLoading] = React.useState<boolean>(false);
  const [validationError, setValidationError] = React.useState<BartApiError>();

  React.useEffect(() => {
    if (!open) {
      return;
    }

    setValidationError(undefined);
    setValidationResponse(undefined);

    setValidationLoading(true);
    apiClient.ValidateWaml(context.localWaml).then(r => {
      setValidationResponseIsValid(r.isValid);
      setValidationResponse(parseValidationData(r.validationResponse));
    }).catch((e: BartApiError) => {
      setValidationError(e);
    }).finally(() => {
      setValidationLoading(false);
    })
  }, [open]);

  React.useEffect(() => {
    if (!context.localWaml) {
      return;
    }

    setWapp(YAML.parse(context.localWaml));
  }, [context.localWaml])

  if (!wapp?.deploy) {
    return <>This <InlineCode>.weave.yaml</InlineCode> has no configured deploys.</>
  }

  return (
    <Modal
      title={validationLoading ? "Validating..." : validationResponseIsValid ? "Validation Succeeded!" : "Validation Failed"}
      open={open}
      fullWidth
      toggleModal={toggleModal}
    >
      {validationLoading && (
        <CircularProgress/>
      )}

      {validationError && (
        <WarningPanel
          severity="error"
          title={"Error Running Validation"}
          defaultExpanded={true}
        >
          <>
            {validationError.cause}

            {validationError.messages?.length && (
              <p>
                Messages:
                <ul>
                  {validationError.messages.map((message, i) => (
                    <li key={i}>{message}</li>
                  ))}
                </ul>
              </p>
            )}

            {validationError.tags?.length && (
              <p>
                Tags:
                <ul>
                  {validationError.tags?.map((tag, i) => {
                    for (const prop in tag) {
                      return <li key={i}>{prop}: {tag[prop]}</li>
                    }

                    return null;
                  })}
                </ul>
              </p>
            )}
          </>
        </WarningPanel>
      )}

      {validationResponse && (
        <div style={{whiteSpace: "pre-wrap"}}>
          {validationResponse.globalResults && (
            <Accordion variant={"elevation"} classes={{root: classes.accordion}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls={`panel-global-results-content`}
                id={`panel-global-results-header`}
                className={classes.validationResultAccordionSummary}
              >
                Global Validation Results - {getConclusionFromResults(validationResponse.globalResults)}
              </AccordionSummary>
              <AccordionDetails>

                {resultsAreEmpty(validationResponse.globalResults) && (
                  <div>Success!</div>
                ) || (
                  <ConclusionColorLegend/>
                )}

                {validationResponse.globalResults.map((result: ValidationResult, i: number) => {
                  if (resultIsEmpty(result)) {
                    return null;
                  }

                  return (
                    <ValidationResultCard
                      key={["validationResults", "globalResults", i].join("-")}
                      validationResult={result}
                    />
                  )
                })}
              </AccordionDetails>
            </Accordion>
          )}

          {Array.from(validationResponse.perDeployResults.keys()).map(deployID => {
            const validationResults = validationResponse.perDeployResults.get(deployID);
            if (!validationResults) {
              return null;
            }

            return (
              <Accordion
                key={["validationResults", "perDeployResults", deployID].join("-")}
                variant={"elevation"}
                classes={{root: classes.accordion}}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls={`panel${deployID}-content`}
                  id={`panel${deployID}-header`}
                  className={classes.validationResultAccordionSummary}
                >
                  {deployID} - {getConclusionFromResults(validationResults)}
                </AccordionSummary>
                <AccordionDetails>
                  <ConclusionColorLegend/>

                  {validationResults?.map((result: ValidationResult, i: number) => {
                    if (resultIsEmpty(result)) {
                      return null;
                    }

                    return (
                      <ValidationResultCard
                        key={["validationResults", "perDeployResults", deployID, i].join("-")}
                        validationResult={result}
                      />
                    )
                  })}
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>
      )}

    </Modal>
  )
}

const useConclusionLegendStyles = makeStyles(_theme => ({
  infoBox: {
    float: "left",
    height: "20px",
    width: "20px",
    marginBottom: "15px",
    border: "1px solid black",
    clear: "both",
  },
  warningBox: {
    float: "left",
    height: "20px",
    width: "20px",
    marginBottom: "15px",
    border: "1px solid black",
    clear: "both",
  },
  errorBox: {
    float: "left",
    height: "20px",
    width: "20px",
    marginBottom: "15px",
    border: "1px solid black",
    clear: "both",
  }
}))

const ConclusionColorLegend = () => {
  const classes = useConclusionLegendStyles();

  return (
    <Grid container>
      <Grid item>
        <div>
          <div className={classes.infoBox} style={{backgroundColor: getBackgroundColorForSeverity("info")}}></div>
          &nbsp;Info
        </div>
      </Grid>
      <Grid item>
        <div>
          <div className={classes.warningBox} style={{backgroundColor: getBackgroundColorForSeverity("warning")}}></div>
          &nbsp;Warning
        </div>
      </Grid>
      <Grid item>
        <div>
          <div className={classes.errorBox} style={{backgroundColor: getBackgroundColorForSeverity("error")}}></div>
          &nbsp;Error
        </div>
      </Grid>
    </Grid>
  )
}

export default WAMLValidationModal;
