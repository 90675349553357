import React, {useContext} from 'react';
import {ChangesNotificationBadge} from "../../../../../core/ChangesNotificationBadge/ChangesNotificationBadge";
import {TabbedLayout} from "@backstage/core-components";
import {WamlContext} from "./WAMLContent";

type Props = {
  routes: WAMLRoute[];
}

type WAMLRoute = {
  path: string;
  title: string;
  content: React.ReactElement;
}

/**
 * Component that renders a TabbedLayout with the provided routes.
 * - this serves as a wrapper for the TabbedLayout component for WAML routes that all have the same context
 * @param props
 * @constructor
 */
const WAMLRoutes = (props: Props) => {
  const context = useContext(WamlContext);

  const getTabProps = (title: string) => {
    return context.tabsWithChanges?.includes(title) ? {
      label: (<ChangesNotificationBadge title={title}/>)
    } : {}
  }

  return (
    <TabbedLayout>
      {props.routes.map(route => (
        <TabbedLayout.Route
          key={route.title}
          path={route.path}
          title={route.title}
          tabProps={getTabProps(route.title)}
        >
          {route.content}
        </TabbedLayout.Route>
      ))}
    </TabbedLayout>
  )
}

export default WAMLRoutes;
