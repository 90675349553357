import React from 'react';
import {Badge} from '@material-ui/core';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import {useUserProfile} from '@backstage/plugin-user-settings';
import IconButton from '@mui/material/IconButton';
import {styled} from '@mui/material/styles';
import {getGradeColor} from './helpers';
import {ScorecardDialog} from './ScorecardDialog';
import {RepositoryEntityV1alpha1} from '../../../../../../../../../backend/src/custom-processors/types';

const BadgeContentSpan = styled('span')<{ gradeLetter?: string }>(
  ({theme, gradeLetter}) => ({
    width: 18,
    height: 18,
    borderRadius: '50%',
    backgroundColor: getGradeColor(gradeLetter),
    color: 'white',
    padding: 3,
    fontWeight: 'bold',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  }),
);

export const riskScoreUserEmails: string[] = [
  // DevX
  'jon.stevens@getweave.com',
  'carson.anderson@getweave.com',
  'katharine.tuck@getweave.com',
  'ganesh.kakkireni@getweave.com',
  'ankit.kumar@getweave.com',
  'kacey.cole@getweave.com',
  // Test-Infra
  'brendan.ashton@getweave.com',
  'jose.salomone@getweave.com',
  'shubham.sharma@getweave.com',
  // Cody Petersen's team (pilot)
  'cody.petersen@getweave.com',
  'anh-dao.nguyen@getweave.com',
  'francisco.arrieta@getweave.com',
  'joshua.clark@getweave.com',
  'olivia.clyde@getweave.com',
  'eric.suh@getweave.com',
  'justin.mayhew@getweave.com',
  // Jerry Evarts' team (pilot)
  'jerry.evarts@getweave.com',
  'curtis.thacker@getweave.com',
  'aditya.kumar@getweave.com',
  'brayden.babbitt@getweave.com',
  'derrick.laird@getweave.com',
  'madasamy.p@getweave.com',
  'palash.vijay@getweave.com',
  'richard.duncan@getweave.com',
  'tarun.sharma@getweave.com',
  // Data-Infra (pilot)
  'frank.harper@getweave.com',
  'jared.moore@getweave.com',
  'casey.mau@getweave.com',
  'catherine.thompson@getweave.com',
  'makayla.paxman@getweave.com',
  'richard.hill@getweave.com',
  'ryan.brereton@getweave.com',
  // Frontend
  'sumit.hingu@getweave.com',
  // P5s
  'eric.gish@getweave.com',
  'pete.kruckenberg@getweave.com',
  'bob.pace@getweave.com',
  'dave.hulihan@getweave.com',
  'paul.mackay@getweave.com',
  'sebastian.sastre@getweave.com',
  // Miscellanous
  'matt.hall@getweave.com',
];

type ScorecardContentProps = {
  entity: RepositoryEntityV1alpha1;
};

const ScorecardContent = (props: ScorecardContentProps) => {
  const entity = props.entity;
  const user = useUserProfile();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const currentUserEmail = user?.profile?.email || '';
  if (!riskScoreUserEmails.includes(currentUserEmail)) {
    return null;
  }

  const reportData = entity?.spec?.qualityMetricsReport || {};
  console.log(reportData);
  return (
    <div style={{textAlign: 'right'}}>
      <IconButton color="primary" onClick={handleOpen}>
        <Badge
          badgeContent={
            <BadgeContentSpan gradeLetter={"A"}>
              A
            </BadgeContentSpan>
          }
          overlap="circular"
        >
          <AssessmentRoundedIcon color="inherit" fontSize="large"/>
        </Badge>
      </IconButton>
      <ScorecardDialog
        open={open}
        onClose={handleClose}
        reportData={reportData}
      />
    </div>
  );
};

export default ScorecardContent;
