import React, {useContext} from "react";
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {CodeEditor} from "../../../../../../core/Editors/CodeEditor";
import {useApi} from "@backstage/core-plugin-api";
import {BartApiError, bartApiRef} from "../../../../../../../api/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import {WarningPanel} from "@backstage/core-components";
import {WamlContext} from "../WAMLContent";
import WAMLValidationModal from "./WAMLValidationModal/WAMLValidationModal";

export const WAMLDefinitionPageTitle = "Definition";

const WAMLDefinition = () => {
  const context = useContext(WamlContext);
  const {originalWaml, localWaml, modifyWaml} = context;
  const [waml, setWaml] = React.useState<string>("");

  const [wamlFormatLoading, setWamlFormatLoading] = React.useState<boolean>(false);
  const [wamlFormatError, setWamlFormatError] = React.useState<string>("");

  const [validateModalOpen, setValidateModalOpen] = React.useState(false);

  const toggleValidateModal = () => {
    setValidateModalOpen(!validateModalOpen);
  }

  const apiClient = useApi(bartApiRef);

  React.useEffect(() => {
    setWaml(localWaml);
  }, [localWaml]);

  const formatWaml = () => {
    setWamlFormatLoading(true);
    apiClient.FormatWaml(waml)
      .then(r => {
        if (!r.waml) {
          return;
        }

        setWaml(r.waml);
        modifyWaml(r.waml, {tab: WAMLDefinitionPageTitle});
      })
      .catch((e: BartApiError) => {
        setWamlFormatError(e.cause);
      })
      .finally(() => {
        setWamlFormatLoading(false);
      });
  }

  return (
    <Grid container>
      <Button
        onClick={() => modifyWaml(waml, {tab: WAMLDefinitionPageTitle})}
        disabled={waml === originalWaml}
        color={"primary"}
        variant={"outlined"}
        style={{marginBottom: "20px", marginRight: "10px"}}
      >Stage Changes</Button>
      <Button
        onClick={formatWaml}
        color={"primary"}
        variant={"outlined"}
        style={{marginBottom: "20px", marginRight: "10px"}}
      >Format & Stage Changes</Button>
      <Button
        onClick={toggleValidateModal}
        color={"primary"}
        variant={"outlined"}
        style={{marginBottom: "20px"}}
      >Validate</Button>

      {wamlFormatLoading && (
        <CircularProgress/>
      )}

      {wamlFormatError && (
        <WarningPanel
          severity="error"
          title={"WAML Formatting Error"}
          defaultExpanded={true}
        >
          {wamlFormatError}
        </WarningPanel>
      )}

      <CodeEditor
        value={waml}
        options={{
          readOnly: false
        }}
        onChange={(value: string | undefined) => {
          if (!value) {
            return;
          }

          setWaml(value);
        }}
      />

      <WAMLValidationModal
        open={validateModalOpen}
        toggleModal={toggleValidateModal}
      />
    </Grid>
  )
};

export default WAMLDefinition;
