import {
  BackstageIdentityApi,
  configApiRef,
  createApiRef,
  OAuthApi,
  oktaAuthApiRef,
  OpenIdConnectApi,
  ProfileInfoApi,
  SessionApi,
  useApi
} from "@backstage/core-plugin-api";
import {
  Bart,
  CreateWamlPRResponse,
  FormatWamlResponse,
  UpdateWamlSyncDocResponse,
  ValidateFullWamlResponse
} from "@weave/schema-gen-ts/dist/schemas/bart";
import {SyncDoc} from "@weave/schema-gen-ts/dist/shared/devx/waml";

export interface BartApi {
  CreateWamlPR: (repo: string, waml: string, title: string, description: string) => Promise<CreateWamlPRResponse>;
  FormatWaml: (waml: string) => Promise<FormatWamlResponse>;
  ValidateWaml: (waml: string) => Promise<ValidateFullWamlResponse>;
  GetWamlSyncDoc: (id: string) => Promise<SyncDoc>;
  UpdateWamlSyncDoc: (id: string, waml: string) => Promise<UpdateWamlSyncDocResponse>;
}

export const bartApiRef = createApiRef<BartApi>({
  id: 'plugin.bart-api.service',
});

export class BartApiClient implements BartApi {
  baseURL: string;
  oktaApi: OAuthApi & OpenIdConnectApi & ProfileInfoApi & BackstageIdentityApi & SessionApi;

  constructor() {
    const config = useApi(configApiRef);
    this.baseURL = config.getConfig('app').getString('bartApiBaseUrl')
    if (!this.baseURL) {
      this.baseURL = "https://bart-api-gateway.gke1-west3.wsf-prod-1.wstack.net";
    }

    this.oktaApi = useApi(oktaAuthApiRef);
  }

  private async handleErrors(response: Response) {
    if (!response.ok) {
      let error = await response.json();
      if (error.message === "") {
        throw new BartApiError({code: 13, cause: response.statusText});
      }

      let messageJson = JSON.parse(error.message);
      throw new BartApiError({
        code: error.code,
        cause: messageJson.cause,
        tags: messageJson.tags,
        messages: messageJson.messages
      });
    }

    return response;
  }

  private async fetch<T extends any>(uri: string, requestInit: RequestInit) {
    const idToken = await this.oktaApi.getIdToken()

    requestInit.headers = {
      ...requestInit.headers,
      "Authorization": `Bearer ${idToken}`
    }

    return fetch(`${this.baseURL}${uri}`, requestInit)
      .then(this.handleErrors)
      .then<T>(res => res.json())
  }

  async CreateWamlPR(repo: string, waml: string, title: string, description: string): Promise<CreateWamlPRResponse> {
    return Bart.CreateWamlPR((uri: string, requestInit: RequestInit): Promise<CreateWamlPRResponse> => {
      return this.fetch<CreateWamlPRResponse>(uri, requestInit)
    }, {repo, waml, title, description})
  }

  async FormatWaml(waml: string): Promise<FormatWamlResponse> {
    return Bart.FormatWaml((uri: string, requestInit: RequestInit): Promise<FormatWamlResponse> => {
      return this.fetch<FormatWamlResponse>(uri, requestInit)
    }, {waml})
  }

  async ValidateWaml(waml: string): Promise<ValidateFullWamlResponse> {
    return Bart.ValidateFullWaml((uri: string, requestInit: RequestInit): Promise<ValidateFullWamlResponse> => {
      return this.fetch<ValidateFullWamlResponse>(uri, requestInit)
    }, {waml})
  }

  async UpdateWamlSyncDoc(id: string, waml: string): Promise<UpdateWamlSyncDocResponse> {
    return Bart.UpdateWamlSyncDoc((uri: string, requestInit: RequestInit): Promise<UpdateWamlSyncDocResponse> => {
      return this.fetch<UpdateWamlSyncDocResponse>(uri, requestInit)
    }, {id, waml})
  }

  async GetWamlSyncDoc(id: string): Promise<SyncDoc> {
    return Bart.GetWamlSyncDoc((uri: string, requestInit: RequestInit): Promise<SyncDoc> => {
      return this.fetch<SyncDoc>(uri, requestInit)
    }, {id})
  }
}

export class BartApiError extends Error {
  code: number;
  cause: string;
  tags?: any[];
  messages?: string[];

  constructor({code, cause, tags, messages}: { code: number, cause: string, tags?: any[], messages?: string[] }) {
    super();
    this.name = "BartApiError";
    this.code = code;
    this.cause = cause;
    this.tags = tags;
    this.messages = messages;
  }
}
