import React, {useContext} from "react";
import Button from "@material-ui/core/Button";
import {Grid} from "@material-ui/core";
import SLOCard from "./SLOCard";
import YAML from "yaml";
import {WamlContext} from "../WAMLContent";
import InlineCode from "../../../../../../core/InlineCode/InlineCode";

export const SLOPageTitle = "SLOs";

const SLOPage = () => {
  const context = useContext(WamlContext);

  const [wapp, setWapp] = React.useState<any>(null);

  React.useEffect(() => {
    if (!context.localWaml) {
      return;
    }

    // Parse the wapp yaml string into an object that we can modify
    setWapp(YAML.parse(context.localWaml));
  }, [context.localWaml]);

  const deleteDeploy = React.useCallback((deployID: string) => {
    const newDeploys = {...wapp.deploy};
    delete newDeploys[deployID];

    context.modifyWaml({
      ...wapp,
      deploy: newDeploys,
    }, {tab: SLOPageTitle});
  }, [wapp])

  const addNewDefaultSection = React.useCallback(() => {
    context.modifyWaml({
      ...wapp,
      defaults: [...wapp.defaults, {match: "*"}],
    }, {tab: SLOPageTitle});
  }, [wapp])

  const deleteDefault = React.useCallback((index: number) => {
    const newDefaults = [...wapp.defaults];
    newDefaults.splice(index, 1);

    context.modifyWaml({
      ...wapp,
      defaults: newDefaults,
    }, {tab: SLOPageTitle});
  }, [wapp]);

  if (!wapp?.deploy) {
    return <div>This <InlineCode>.weave.yaml</InlineCode> has no configured deploys.</div>
  }

  return (
    <Grid container>
      <Button
        onClick={addNewDefaultSection}
        color={"primary"}
        variant={"outlined"}
        style={{marginBottom: "20px"}}
      >Add New Default Section</Button>

      {wapp.defaults?.map((defaultItem: any, i: number) => {
        const key = [wapp.slug, "defaultItem", i].join("-");
        return (
          <Grid item xs={12} key={key}>
            <SLOCard
              wapp={wapp}
              defaultsIndex={i}
              title={`Defaults - Match: ${defaultItem.match}`}
              deleteDefault={deleteDefault}
              sloSpec={defaultItem.slo}
            />
          </Grid>
        )
      })}

      {Object.keys(wapp.deploy).sort().map((deployID: any, i: number) => {
        const key = [wapp.slug, "deployItem", i].join("-");
        return (
          <Grid item xs={12} key={key}>
            <SLOCard
              wapp={wapp}
              title={`Deploy: ${deployID}`}
              deployID={deployID}
              deleteDeploy={deleteDeploy}
              sloSpec={wapp.deploy[deployID].slo}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default SLOPage;

